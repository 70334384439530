'use client';

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import * as React from 'react';
import { BiChevronDown, BiMenu } from 'react-icons/bi';

import ButtonLink from '@/components/links/ButtonLink';
import UnstyledLink from '@/components/links/UnstyledLink';
import NextImage from '@/components/NextImage';
import Typography from '@/components/Typography';
import clsxm from '@/lib/clsxm';
import { getToken } from '@/lib/cookies';

/**
 * @description Navbar component
 *
 * @function DropdownEvents when desktop, and events button clicked
 * @function DropdownEventsMobile when mobile, and events button clicked
 */

type NavbarLink = {
  name: string;
  desc: string;
  href: string;
  classname: string;
  bgclassname: string;
  hoverclassname: string;
}[];

const navbarEvents: NavbarLink = [
  {
    name: 'Schematics NLC',
    desc: 'National Logic Competition',
    href: '/nlc',
    classname: 'text-warning-main',
    bgclassname: 'bg-warning-main/10',
    hoverclassname: 'hover:bg-warning-main/10',
  },
  {
    name: 'Schematics NPC',
    desc: 'National Programming competition',
    href: '/npc',
    classname: 'text-danger-main',
    bgclassname: 'bg-danger-main/10',
    hoverclassname: 'hover:bg-danger-main/10',
  },
  {
    name: 'Schematics BST',
    desc: 'Bootcamp, Seminar, and Technology',
    href: '/bst',
    classname: 'text-success-main',
    bgclassname: 'bg-success-main/10',
    hoverclassname: 'hover:bg-success-main/10',
  },
  {
    name: 'Schematics REEVA',
    desc: 'Revolutionary Entertainment and Expo with Various Arts',
    href: '/reeva',
    classname: 'text-reeva-main',
    bgclassname: 'bg-reeva-main/10',
    hoverclassname: 'hover:bg-reeva-main/10',
  },
];

export default function Navbar() {
  const token = getToken();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenEvent, setIsOpenEvent] = useState<boolean>(false);

  React.useLayoutEffect(() => {
    if (token) {
      setIsLogin(true);
    }
  }, [token]);

  return (
    <nav
      className={clsxm(
        'sticky top-0 z-[101]',
        'w-full bg-typo-main text-white',
      )}
    >
      {/* Section Navbar aslinya */}
      <section className='flex w-full items-center justify-between px-6 py-6 lg:px-[6.25rem]'>
        <section>
          <UnstyledLink href='/'>
            <NextImage
              src='/logo-sch.png'
              className='w-[116px] lg:w-[181px]'
              alt='Logo Schematics'
              width={362}
              height={100}
            />
          </UnstyledLink>
        </section>

        {/* Desktop Version */}
        <section className='hidden items-center space-x-12 lg:flex'>
          <DropdownEvents />
          <div className='space-x-6'>
            {!isLogin ? (
              <>
                <ButtonLink href='/register'>Register</ButtonLink>
                <ButtonLink
                  href='/login'
                  variant='outline'
                  className='text-typo-white'
                >
                  Login
                </ButtonLink>
              </>
            ) : (
              <ButtonLink href='/dashboard' size='sm'>
                Dashboard
              </ButtonLink>
            )}
          </div>
        </section>

        {/* Mobile Version */}
        <div onClick={() => setIsOpen(!isOpen)} className='lg:hidden'>
          <BiMenu
            size={24}
            className={isOpen ? 'text-primary-info-main' : ''}
          />
        </div>
      </section>

      {/* Section tambahan pas mobile  */}
      <section
        className={clsxm(
          'space-y-6 p-6',
          'lg:hidden',
          'transition duration-300 ease-in-out',
          isOpen ? 'block' : 'hidden',
        )}
      >
        <section>
          <div
            className='mb-4 flex space-x-2'
            onClick={() => setIsOpenEvent(!isOpenEvent)}
          >
            <Typography
              as='p'
              variant='bt'
              weight='medium'
              font='LemonMilk'
              className='text-base'
            >
              EVENTS
            </Typography>
            <BiChevronDown
              size={20}
              className={isOpenEvent ? 'rotate-180' : ''}
            />
          </div>
          {/* Section tambahan events mobile */}
          <DropdownEventsMobile isOpenEvent={isOpenEvent} />

          <UnstyledLink href='/tentang-kami'>
            <Typography
              as='p'
              variant='bt'
              weight='medium'
              font='LemonMilk'
              className='text-base'
            >
              TENTANG KAMI
            </Typography>
          </UnstyledLink>

          <UnstyledLink href='/merchandise'>
            <Typography
              as='p'
              variant='bt'
              weight='medium'
              font='LemonMilk'
              className='text-base'
            >
              MERCH
            </Typography>
          </UnstyledLink>
        </section>

        <div className='flex flex-col space-y-4'>
          {!isLogin ? (
            <>
              <ButtonLink href='/register' size='sm'>
                Register
              </ButtonLink>
              <ButtonLink
                href='/login'
                variant='outline'
                size='sm'
                className='text-typo-white'
              >
                Login
              </ButtonLink>
            </>
          ) : (
            <ButtonLink href='/dashboard' size='sm'>
              Dashboard
            </ButtonLink>
          )}
        </div>
      </section>
    </nav>
  );
}

function DropdownEvents() {
  const pathname = usePathname();

  return (
    <div className='space-x-4 lg:flex'>
      <Dropdown>
        <DropdownTrigger>
          <Button
            variant='shadow'
            className={clsxm(
              'flex items-center space-x-2 border-none',
              'hover:text-typo-inline active:text-typo-outline-1',
            )}
          >
            <Typography as='p' variant='bt' weight='medium' font='LemonMilk'>
              EVENTS
            </Typography>
            <BiChevronDown size={20} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label='Dynamic Actions'
          items={navbarEvents}
          variant='shadow'
          className=' rounded-lg border-none bg-typo-main p-6 hover:border-none lg:left-1/3 '
        >
          {(event) => (
            <DropdownItem
              key={event.name}
              href={event.href}
              className={clsxm(
                'my-2 overflow-visible rounded-lg p-2 ',
                pathname === event.href && `${event.bgclassname}`,
                `${event.hoverclassname}`,
              )}
            >
              <Typography
                as='p'
                variant='btn'
                weight='medium'
                font='LemonMilk'
                className={event.classname}
              >
                {event.name}
              </Typography>

              {pathname === event.href && (
                <Typography as='p' variant='c' className='mt-2 text-[#fff] '>
                  {event.desc}
                </Typography>
              )}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      <UnstyledLink
        href='/tentang-kami'
        className='hover:text-typo-inline active:text-typo-outline-1'
      >
        <Typography as='p' variant='bt' weight='medium' font='LemonMilk'>
          TENTANG KAMI
        </Typography>
      </UnstyledLink>

      <UnstyledLink href='/merchandise'>
        <Typography
          as='p'
          variant='bt'
          weight='medium'
          font='LemonMilk'
          className='text-base'
        >
          MERCH
        </Typography>
      </UnstyledLink>
    </div>
  );
}

function DropdownEventsMobile({ isOpenEvent }: { isOpenEvent: boolean }) {
  const pathname = usePathname();

  return (
    <section
      className={clsxm(
        'mb-3 transition duration-300 ease-in-out',
        isOpenEvent ? 'block' : 'hidden',
      )}
    >
      {navbarEvents.map((event) => (
        <UnstyledLink href={event.href} key={event.name}>
          <div
            className={clsxm(
              'rounded-lg p-2',
              pathname === event.href && `${event.bgclassname}`,
            )}
          >
            <Typography
              as='p'
              variant='c'
              weight='medium'
              font='LemonMilk'
              className={clsxm('text-sm/6', event.classname)}
            >
              {event.name}
            </Typography>

            <Typography as='p' variant='c1' className='text-xs/6 text-[#fff]'>
              {event.desc}
            </Typography>
          </div>
        </UnstyledLink>
      ))}
    </section>
  );
}
